import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { BlogSection } from "../components/BlogSection";

const BlogPage = () => {
    return (
        <Layout>
            <SEO title="Blog" />
            <BlogSection showAll={true} />
        </Layout>
    );
};

export default BlogPage;
